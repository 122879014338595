import React, { Fragment, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Container } from "react-bootstrap";
import Sidebar from '../../Component/Sidebar';
import { loadCVData } from "../../actions/cVActions";


const Cv = (props) => {

    const slug = props.match.params.slug;

    // console.log('slug',slug);
    
    const dispatch = useDispatch();

    useEffect(() => {
    dispatch(loadCVData(slug));
    }, [dispatch, slug]);

    const {
        cVData,
    } = useSelector((state) => ({
      cVData: state.cVData,
    }));
    
    useEffect(() => {

        if(!!slug){
        document.title = slug;
        }

    }, [slug]);
    // console.log('cVData', cVData);

    return (

        <>

            <section className="page_title">
                <Container>
                    <h1>About</h1>
                </Container>
            </section>

            <section className="about_page">
                <Container>
                    <div className="main_contnat">
                        <div className="page_main_outer">
                            <Sidebar module={'Cv'} />


                            <div className="pagemain_right">
                                <div className="right_main_contant">
                                    <div className="cv-0detail-outer">
                                        {
                                            cVData &&
                                            cVData.length>0 &&
                                            cVData.map( ( item, key ) => (
                                                <Fragment  key={key}>
                                                    
                                                {  (item.cat_data_count>0 ||
                                                   item.sub_category_count>0) && (
                                                <div className="cv-0detail-list">
                                                    <h4>{item.cat_title}</h4>
                                                    {
                                                        item.cat_data_count>0 &&
                                                        item.data &&
                                                        item.data.length>0 &&
                                                        item.data.map( ( cat_item, cat_key ) => (
                                                        <p key={cat_key}><small>{!!(cat_item.end_date) ? cat_item.end_date : "" }{ !!(cat_item.start_date) ? " - " + cat_item.start_date.split('-')[0] : "" } { !!(cat_item.end_date) ? ":" : "" } </small>{cat_item.title}</p>
                                                    )) }
                                                    {  
                                                        item.sub_category_count>0 &&
                                                        item.sub_category &&
                                                        item.sub_category.length>0 &&
                                                        item.sub_category.map( ( subcat_item, subcat_key ) =>  (
                                                        <div className="cv-0detail-list" key={subcat_key}>
                                                            <h5>{subcat_item.title}</h5>
                                                            {
                                                                subcat_item.sub_cat_data_count>0 &&
                                                                subcat_item.sub_cat_data &&
                                                                subcat_item.sub_cat_data.length>0 &&
                                                                subcat_item.sub_cat_data.map( ( subcat_cv_item, subcat_cv_key ) => (
                                                            <p key={subcat_cv_key}><small>{!!(subcat_cv_item.end_date) ? subcat_cv_item.end_date : "" }{ !!(subcat_cv_item.start_date) ? " - " + subcat_cv_item.start_date.split('-')[0] : "" } { !!(subcat_cv_item.end_date) ? ":" : "" } </small>{subcat_cv_item.title}</p>
                                                            )) }
                                                        </div>
                                                    )) }

                                                </div>
                                                )}


                                                {  item.cat_data_count<1 &&
                                                    item.sub_category_count<1 &&
                                                    !!slug &&
                                                (
                                                    <div className="cv-0detail-list">
                                                        <h4>{item.cat_title}</h4>
                                                        <h6 align="center">No CV Found</h6>
                                                    </div>
                                                )}
                                                </Fragment>
                                            ))
                                        }

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </Container>
            </section>



        </>
    )
}
export default Cv;