import React, { useState, useEffect, Fragment } from "react";
import { Modal, Container } from "react-bootstrap";
import Slider from "react-slick";
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { loadWorkData } from "../../actions/workActions";

const WorkDetail = (props) => {
  
  const slug = props.match.params.slug;
  
  // console.log('slug',slug);

  const dispatch = useDispatch();
  useEffect(() => {
  dispatch(loadWorkData(slug));
  }, [dispatch, slug]);

  const {
    workData,
  } = useSelector((state) => ({
    workData: state.workData,
  }));

  // console.log('workData', workData);

  const work_detail_slider = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "350px",
    slidesToShow: 1,
    speed: 500,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 1680,
        settings: {
          centerPadding: "200px",
        },
      },
      {
        breakpoint: 1366,
        settings: {
          centerPadding: "150px",
        },
      },
      {
        breakpoint: 991,
        settings: {
          centerPadding: "100px",
        },
      },
      {
        breakpoint: 767,
        settings: {
          centerPadding: "60px",
        },
      },
      {
        breakpoint: 575,
        settings: {
          centerPadding: "40px",
        },
      },
    ],
  };

  const [show, setShow] = useState(false);
  const [getGallery, setGallery] = useState([]);
  const [getDetails, setDetails] = useState([]);
  
  useEffect(() => {
    
      workData && 
      workData.length>0 && 
      workData.map( ( item, key ) => (
        key === 0 && (
        setDetails(item)
        
        )
      ))
      
    }, [workData]);

  useEffect(() => {
      setGallery(getDetails.images);
      document.title=getDetails.title + ' | Kanchana Gupta';
    }, [getDetails]);

      // console.log('getGallery',getGallery);
      // console.log('getDetails',getDetails);
      // console.log('getGallery.media',getGallery[0].media);
      

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const gallery_slider = {
    customPaging: function (i) {
      console.log(i);
      if(getGallery && getGallery.length > 0){
        return (
          <a>
            <img src={getGallery[i].media} alt={getDetails.title} />
          </a>
        );
      // console.log('getGallery.media anil',getGallery[i].media_name);
      }
    },
    dots: true,
    dotsClass: "slick-dots slick-thumb",
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
  };

  return (
    <>
    { workData && 
      workData.length>0 &&
      workData.map( ( item, key) => (
      (
      <Fragment key={key}>
        <section className="page_title">
          <Container>
            <div className="d-flex align-items-center">
              <h1>{item.title}</h1>
              <a href="#"
                onClick={handleShow}
                className="ms-auto btn outline_btn btn_white mt-0"
              >
                View Gallery
              </a>
            </div>
          </Container>
        </section>
        <div className="work_detail_wrap">
          <div className="work_detail arrow_icon">
            <Slider {...work_detail_slider}>
              {
                item.images &&
                item.images.map( (img_item, key) => (
              <div key={key}>
                <img src={img_item.media} alt={item.title} />
              </div>
              ) ) }
            </Slider>
          </div>

          <div className="detail_content">
            <Container>
              <h2>{item.title}</h2>
              <br />
              <p dangerouslySetInnerHTML={{__html: item.description}}></p>
            </Container>
            <div className="detail_next_prev">
              <Container>
                <div className="d-flex align-items-center">
                  {
                    item.previous && (
                      <Link to={ "/work-detail/" + item.previous} className="">
                    <img src={process.env.REACT_APP_PUBLIC_URL + "/assets/images/prev.svg"} className="me-2" alt="" />
                    Previous
                  </Link>
                  )}
                  {
                    item.next && (
                      <Link to={ "/work-detail/" + item.next} className="ms-auto">
                    Next{" "}
                    <img
                      src={process.env.REACT_APP_PUBLIC_URL + "/assets/images/arrow.svg"}
                      className="ms-2"
                      alt=""
                    />
                  </Link>
                  )}
                </div>
              </Container>
            </div>
          </div>
        </div>

        <Modal
          show={show}
          onHide={handleClose}
          className="gallery_modal"
          centered
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body className="arrow_icon thumbnail">
            <Slider {...gallery_slider}>
              {
                item.images &&
                item.images.map( (img_item, key) => (
              <div key={key}>
                <img src={img_item.media} alt={item.title} />
              </div>
              ) ) }
            </Slider>
            
            {/* <Slider {...gallery_slider}>
              <div>
                <img src={process.env.REACT_APP_PUBLIC_URL + "/assets/images/gallery1.jpg"} alt="" />
              </div>
              <div>
                <img src={process.env.REACT_APP_PUBLIC_URL + "/assets/images/gallery2.jpg"} alt=""/>
              </div>
              <div>
                <img src={process.env.REACT_APP_PUBLIC_URL + "/assets/images/gallery3.jpg"} alt="" />
              </div>
              <div>
                <img src={process.env.REACT_APP_PUBLIC_URL + "/assets/images/gallery4.jpg"} alt="" />
              </div>
            </Slider> */}
          </Modal.Body>
        </Modal>
      </Fragment>
    ) )) }
    </>
  );
};
export default WorkDetail;
