import React, { useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Link } from 'react-router-dom';
import { Container } from "react-bootstrap";
import Sidebar from '../../Component/Sidebar';  
import { loadWorkData } from "../../actions/workActions";


const Work = (props) => {

    const slug = props.match.params.slug;

    const dispatch = useDispatch();
    useEffect(() => {
    dispatch(loadWorkData(slug));
    }, [dispatch, slug]);

    const {
      workData,
    } = useSelector((state) => ({
      workData: state.workData,
    }));
    
    // console.log('workData', workData);

    return (

        <>

            <section className="page_title">
                <Container>
                    <h1>Works</h1>
                </Container>
            </section>

            <section className="about_page">
                <Container>
                    <div className="main_contnat">
                        <div className="page_main_outer">
                            <Sidebar module={'Work'}/>


                            <div className="pagemain_right">
                                <div className="work-page_outer">
                                    {
                                    workData &&
                                    workData.length>0 &&
                                    workData.map( ( item, key ) => (
                                    <div className="horizantel_grid" key={key}>
                                        <figure><Link to={ "/work-detail/" + item.slug}><img alt={item.title} src={item.media} /></Link></figure>
                                        <div className="list_info">
                                            <h3><Link to={ "/work-detail/" + item.slug}>{item.title}</Link ></h3>
                                            <p dangerouslySetInnerHTML={{__html: item.short_description}}></p>
                                            <Link to={ "/work-detail/" + item.slug} className="readmore">Read more</Link >
                                        </div>
                                    </div>
                                    ))}

                                </div>

                            </div>
                        </div>



                    </div>
                </Container>
            </section>



        </>
    )
}
export default Work;