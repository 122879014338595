import React, { useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Link } from 'react-router-dom';
import { Container } from "react-bootstrap";
import { loadSoloShowData } from "../../actions/soloShowActions";


const Soloshows = () => {

    const dispatch = useDispatch();
    useEffect(() => {
    dispatch(loadSoloShowData());
    }, [dispatch]);

    const {
        soloShowData,
    } = useSelector((state) => ({
      soloShowData: state.soloShowData,
    }));
    
    // console.log('soloShowData', soloShowData);

    return (

        <>

            <section className="page_title">
                <Container>
                    <h1>Solo Shows</h1>
                </Container>
            </section>

            <section className="about_page">
                <Container>
                    <div className="main_contnat">
                        <div className="page_main_outer">
                            
                            <div className="soloshows">

                                {
                                    soloShowData &&
                                    soloShowData.length>0 &&
                                    soloShowData.map( ( item, key ) => (
                                <div className="horizantel_grid" key={key}>
                                    <figure><a href={ process.env.REACT_APP_PUBLIC_URL + "/solo-show-details/" +  item.slug}><img alt={item.title} src={item.media} /></a></figure>
                                    <div className="list_info">
                                        <h3><a href={ process.env.REACT_APP_PUBLIC_URL + "/solo-show-details/" +  item.slug}>{item.title}</a></h3>
                                        <div className="solor_venue">
                                            { !!(item.publish_date) ? (
                                            <span className="date"><img alt="Kanchana Gupta" src={process.env.REACT_APP_PUBLIC_URL + "/assets/images/time.svg"} />{item.publish_date}</span>
                                            ) : '' }
                                            { !!(item.address) ? (
                                            <span className="locationfix"><img alt="Kanchana Gupta" src="./assets/images/location.svg" />{item.address}</span> ) : '' }
                                        </div>
                                        <p dangerouslySetInnerHTML={{__html: item.short_description}}></p>
                                        <a href={ process.env.REACT_APP_PUBLIC_URL + "/solo-show-details/" +  item.slug} className="readmore">Read more</a>
                                    </div>
                                </div>
                                ))}

                            </div>
                        </div>

                    </div>
                </Container>
            </section>



        </>
    )
}
export default Soloshows;