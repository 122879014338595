import React, { useEffect, Fragment } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Link } from 'react-router-dom';
import { Col, Row, Container } from "react-bootstrap";
import Sidebar from '../../Component/Sidebar';
import { loadPublicationData } from "../../actions/publicationActions";


const Publications = (props) => {

    const slug = props.match.params.slug;
    
    const dispatch = useDispatch();
    useEffect(() => {
    dispatch(loadPublicationData(slug));
    }, [dispatch,slug]);

    const {
        publicationData,
    } = useSelector((state) => ({
      publicationData: state.publicationData,
    }));
    
    // console.log('publicationData', publicationData);

    return (

        <>
            <section className="page_title">
                <Container>
                    <h1>Publications</h1>
                </Container>
            </section>

            <section className="about_page">
                <Container>
                    <div className="main_contnat">
                        <div className="page_main_outer">
                            <Sidebar module={'Publication'} />

                            <div className="pagemain_right">
                                <div className="media_page publications_outer">
                                    {/* <h3>Catalogues and Publications</h3> */}

                                    {
                                        publicationData &&
                                        publicationData.length>0 &&
                                        publicationData.map( ( item, key) => (
                                            <Fragment  key={key}>
                                            {  item.cat_data_count>0 && (

                                    <div className="media_link" key={key}>
                                        <h3>{item.cat_title}</h3>
                                        {
                                            item.cat_data_count>0 && (
                                        // <ul>
                                        //     {
                                        //     item.data &&
                                        //     item.data.length>0 &&
                                        //     item.data.map( ( item, key) => (
                                        //         <li key={key}>
                                        //             {
                                        //                 item.publication_type === "no_link" ?
                                        //                 (
                                        //                     <a href="#">{item.title}</a>
                                        //                 )
                                        //                 :
                                        //                 (
                                        //                     <a href={item.media_file ? item.media_file : item.extenal_link} rel="noreferrer" target="_blank">{item.title}</a>
                                        //                 ) }
                                        //         { !!(item.publication_date) && ( <span>{item.publication_date}</span> ) }
                                        //         </li>
                                        //     ))}
                                        // </ul>
                                        <Row className="publication-list events_listing-outer px-2">
                                            {
                                            item.data &&
                                            item.data.length>0 &&
                                            item.data.map( ( item, key) => (
                                            <Col md={6} sm={12} key={key}>
                                                <div className="event_listing verticle_grid">
                                                    { item.media && (
                                                    <Link to={ "/publication-detail/" + item.slug }><figure><img alt={item.title} src={item.media} /></figure></Link>
                                                    ) }
                                                    <div className="list_info">
                                                        <h3><Link to={ "/publication-detail/" + item.slug }>{item.title}</Link></h3>
                                                        { !!item.publication_date && ( <span className="date"><img alt="Kanchana Gupta" src={process.env.REACT_APP_PUBLIC_URL + "/assets/images/time.svg"} />{item.publication_date}</span> ) }
                                                        { !!item.short_description && ( <p>{item.short_description}</p> ) }
                                                        <Link to={ "/publication-detail/" + item.slug } className="readmore">Read more</Link>
                                                    </div>
                                                </div>
                                            </Col>
                                            ))}
                                        </Row>
                                        )}
                                    </div>
                                                )}

                                                {  item.cat_data_count<1 &&
                                                    !!slug &&
                                                (
                                                    <div className="media_link" key={key}>
                                                    <h3>{item.cat_title}</h3>
                                                    <br />
                                                    <h6 align="center">No Publication Found</h6>
                                                    </div>
                                                )}
                                                </Fragment>
                                    )) }

                                </div>
                            </div>
                        </div>

                    </div>
                </Container>
            </section>
        </>
    )
}
export default Publications;