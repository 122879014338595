import React, { useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Link } from 'react-router-dom';
import { Col, Row, Container} from "react-bootstrap"; 
import { loadNewsData } from "../../actions/newsActions";


const News = () => {

    const dispatch = useDispatch();
    useEffect(() => {
    dispatch(loadNewsData());
    }, [dispatch]);
    
    const {
      newsData,
    } = useSelector((state) => ({
      newsData: state.newsData,
    }));
    
    // console.log('newsData',newsData);

    return (

        <>

            <section className="page_title">
                <Container>
                    <h1>News</h1>
                </Container>
            </section>

            <section className="about_page page-content">
                <Container>
                    <Row className="events_listing-outer">
                        {
                            newsData &&
                            newsData.length>0 &&
                            newsData.map( ( item, key ) => (
                        <Col md={4} sm={6} key={key}>
                            <div className="event_listing verticle_grid">
                            <Link to={ "/news-detail/" + item.slug }><figure><img alt={item.title} src={item.media} /></figure></Link>
                                <div className="list_info">
                                    <h3><Link to={ "/news-detail/" + item.slug }>{item.title}</Link></h3>
                                    { !!item.publish_date && ( <span className="date"><img alt="Kanchana Gupta" src={process.env.REACT_APP_PUBLIC_URL + "/assets/images/time.svg"} />{item.publish_date}</span> ) }
                                    { !!item.address && ( <span className="locationfix"><img alt="Kanchana Gupta" src={process.env.REACT_APP_PUBLIC_URL + "/assets/images/location.svg"} />{item.address}</span> ) }
                                    { !!item.short_description && ( <p>{item.short_description}</p> ) }
                                    <Link to={ "/news-detail/" + item.slug } className="readmore">Read more</Link>
                                </div>
                            </div>
                        </Col>
                        )) }

                    </Row>
                </Container>
            </section>



        </>
    )
}
export default News;