import { combineReducers } from "redux";
import initialState from "./initialState";
import homePageData from "./homePageReducer";
import settingsData from "./settingsReducer";
import sidebarData from "./sidebarReducer";
import eventData from "./eventReducer";
import newsData from "./newsReducer";
import aboutUsData from "./aboutUsReducer";
import cvCategoryData from "./cvCategoryReducer";
import workData from "./workReducer";
import soloShowData from "./soloShowReducer";
import otherShowData from "./otherShowReducer";
import mediaGalleryData from "./mediaGalleryReducer";
import mediaData from "./mediaReducer";
import publicationData from "./publicationReducer";
import publicationDetailData from "./publicationDetailReducer";
import cVData from "./cVReducer";
import videoData from "./videoReducer";
import redirectTo from "./redirectTo";

const rootReducer = combineReducers({
  homePageData,
  settingsData,
  sidebarData,
  eventData,
  newsData,
  aboutUsData,
  cvCategoryData,
  workData,
  soloShowData,
  otherShowData,
  mediaData,
  mediaGalleryData,
  cVData,
  publicationData,
  videoData,
  publicationDetailData,
  redirectTo,
});

export default function combinedRootReducer(state, action) {
  return action.type === "LOG_OUT"
    ? rootReducer(initialState, action)
    : rootReducer(state, action);
}
