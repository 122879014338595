import * as types from '../actions/actionsTypes';
import initialState from './initialState';

export default function newsReducer(state = initialState.newsData, action){
    switch(action.type) {
        case types.LOAD_NEWS_DATA_SUCCESS:
            return action.newsData;
        default:
            return state;
    }
}