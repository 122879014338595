import * as types from "./actionsTypes";
import { agent } from "../utils/agent";
import toggleNetworkRequestStatus from "./toggleNetworkRequestStatus";
import * as API from "../utils/apiPath";

export function loadWorkDataSuccess(workData) {
    return { type: types.LOAD_WORK_DATA_SUCCESS, workData };
}

export function loadWorkData(slug) {
    return async function(dispatch){
        dispatch(toggleNetworkRequestStatus(true));
        if(!!slug){
            await agent
            .post(API.WORK_BY_SLUG_DATA, { slug: slug })
            .then((response) => {
                if(response.status === 200 || response.status === 201){
                    // console.log(response.data.data);
                    dispatch(loadWorkDataSuccess(response.data.data));
                }
            })
            .catch((error) => {
                dispatch(toggleNetworkRequestStatus(false));
                throw error;
            })
        }
        else{
            await agent
            .get(API.WORK_DATA, {})
            .then((response) => {
                if(response.status === 200 || response.status === 201){
                    // console.log(response.data.data);
                    dispatch(loadWorkDataSuccess(response.data.data));
                }
            })
            .catch((error) => {
                dispatch(toggleNetworkRequestStatus(false));
                throw error;
            })
        }
    }
}
