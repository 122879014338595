import * as types from "./actionsTypes";
import { agent } from "../utils/agent";
import toggleNetworkRequestStatus from "./toggleNetworkRequestStatus";
import * as API from "../utils/apiPath";
import { showToasterWithOk, showErrorToaster } from "../utils/helpers";

export function loadEventDataSuccess(eventData) {
    return { type: types.LOAD_EVENT_DATA_SUCCESS, eventData };
}

export function loadEventData(slug) {
    return async function(dispatch){
        dispatch(toggleNetworkRequestStatus(true));
        if(!!slug){
            await agent
            .post(API.EVENT_BY_SLUG_DATA, { slug: slug })
            .then((response) => {
                if(response.status === 200 || response.status === 201){
                    // console.log(response.data.data);
                    dispatch(loadEventDataSuccess(response.data.data));
                }
                else{
                    dispatch(loadEventDataSuccess([]));
                }
            })
            .catch((error) => {
                dispatch(toggleNetworkRequestStatus(false));
                throw error;
            })
        }
        else{
        await agent
            .get(API.EVENT_DATA, {})
            .then((response) => {
                if(response.status === 200 || response.status === 201){
                    // console.log(response.data.data);
                    dispatch(loadEventDataSuccess(response.data.data));
                }
            })
            .catch((error) => {
                dispatch(toggleNetworkRequestStatus(false));
                throw error;
            })
        }
    }
}

export function loadEventTypeData(event_type) {
    return async function(dispatch){
        dispatch(toggleNetworkRequestStatus(true));
        // console.log('event_type',event_type);
        await agent
        .get(API.EVENT_DATA + '?type=' + event_type, { })
        .then((response) => {
            if(response.status === 200 || response.status === 201){
                // console.log(response.data.data);
                dispatch(loadEventDataSuccess(response.data.data));
            }
        })
        .catch((error) => {
            dispatch(toggleNetworkRequestStatus(false));
            throw error;
        })
    }
}

// export function handleSubmitRSVP(params) {
//     return async function(dispatch){

//         console.log('rsvp_params',params);
//         const history = useHistory();
//         await agent
//         .post(API.SUBMIT_RSVP, params)
//         .then((response) => {
//             setToaster(response.data.message)
//             history.push({
//                 pathname: "/event-detail/" + params.slug,
//             });
//         })
//         .catch((error) => {
//             dispatch(toggleNetworkRequestStatus(false));
//             throw error;
//         })
//     }
// }

export function handleSubmitRSVP(params, setShow, setLoader) {
    return async function (dispatch) {
      
    //   console.log('rsvp_params',params);

      dispatch(toggleNetworkRequestStatus(true));
      await agent
        .post(API.SUBMIT_RSVP, params)
        .then((response) => {
            setLoader(false);
            // console.log(response);
            if(response.data.status===200){
                showToasterWithOk(response.data.message);
                document.getElementById("rsvp_full_name").value = "";
                document.getElementById("rsvp_email").value = "";
                setShow(false);
            }
            else{
                showErrorToaster(response.data.message);
            }
        })
        .catch((error) => {
          throw error;
        });
    };
}