import * as types from '../actions/actionsTypes';
import initialState from './initialState';

export default function mediaReducer(state = initialState.mediaData, action){
    switch(action.type) {
        case types.LOAD_MEDIA_DATA_SUCCESS:
            return action.mediaData;
        default:
            return state;
    }
}