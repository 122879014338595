import React, { useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Container} from "react-bootstrap"; 
import Sidebar from '../../Component/Sidebar';  
import { loadAboutUsData } from "../../actions/aboutUsActions";

const About = () => {

    const dispatch = useDispatch();
    useEffect(() => {
    dispatch(loadAboutUsData());
    }, [dispatch]);

    const {
        aboutUsData,
    } = useSelector((state) => ({
    aboutUsData: state.aboutUsData,
    }));

    // console.log('aboutUsData',aboutUsData);

    return (

        <>

            <section className="page_title">
                <Container>
                    <h1>About</h1>
                </Container>
            </section>

            <section className="about_page">
                <Container>
                    <div className="main_contnat">
                        <div className="page_main_outer">

                            <Sidebar module={'About'}/>

                            <div className="pagemain_right">
                                { 
                                    aboutUsData && 
                                    aboutUsData.length>0 &&
                                    aboutUsData.map((item, key) => (
                                <div className="right_main_contant" key={key}>
                                    <div className="biography">
                                        <figure><img alt="Kanchana Gupta" src={item.media} /></figure>
                                        <div className="Biography_detail">
                                            <strong>Biography</strong>
                                            <small>{item.title}</small>
                                        </div>
                                    </div>

                                    <div dangerouslySetInnerHTML={{__html: item.description}}></div>

                                </div>
                                ))}
                            </div>

                        </div>
                    </div>
                </Container>
            </section>



        </>
    )
}
export default About;