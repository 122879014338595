import React, { useEffect, Fragment, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Modal, Container, Form, Button } from "react-bootstrap"; 
import { loadEventData, handleSubmitRSVP } from "../../actions/eventActions";
import { setToaster } from "../../utils/helpers";
import {
  
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  EmailShareButton
 
} from "react-share";
import { useForm } from 'react-hook-form';
import { decode } from 'html-entities';


const Eventsdetail = (props) => {

    const [getDetails, setDetails] = useState([]);
    const [show, setShow] = useState(false);
    const [getLoader, setLoader] = useState(false);
    const { register, handleSubmit } = useForm();
    
    const slug = props.match.params.slug;

    // console.log('slug',slug);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

    const dispatch = useDispatch();
    useEffect(() => {
    dispatch(loadEventData(slug));
    }, [dispatch, slug]);
    
    const {
      eventData,
    } = useSelector((state) => ({
      eventData: state.eventData,
    }));
    
    useEffect(() => {
    
    eventData && 
    eventData.length>0 && 
    eventData.map( ( item, key ) => (
        key === 0 && (
        setDetails(item)
        )
        ))
        
    }, [eventData]);
    // console.log('getDetails',getDetails);
    
    const page_url=window.location.href;
    
    var pageDesc=getDetails.short_og_description;
    document.title=getDetails.title;
    document.getElementsByTagName('meta')["description"].content = pageDesc;
    document.getElementsByTagName('meta')[9].content = getDetails.title;
    document.getElementsByTagName('meta')[10].content = pageDesc;
    document.getElementsByTagName('meta')[7].content = page_url;
    document.getElementsByTagName('meta')[11].content = getDetails.media;
    document.getElementsByTagName('meta')[14].content = getDetails.title;
    document.getElementsByTagName('meta')[15].content = pageDesc;
    document.getElementsByTagName('meta')[16].content = getDetails.media;

    // console.log('eventData',eventData);
    // console.log('window.location.href',window.location.href);

    function copy() {
        const el = document.createElement("input");
        el.value = window.location.href;
        document.body.appendChild(el);
        el.select();
        document.execCommand("copy");
        document.body.removeChild(el);
        setToaster('Link Copied!');
    }

    const onSubmit = (data) => {
    //   console.log('data',data);
        const request = {};
        if(!!data.full_name && !!data.email){
            request.full_name = data.full_name;
            request.email = data.email;
            request.slug = slug;
            // setFormError(false);
            setLoader(true);
            dispatch(handleSubmitRSVP(request,setShow,setLoader));
        }
        else{
        // setFormError(true);
        }
    };

    // const onSubmit = (data) => {
  
    //     setLoader(true);
    //     // console.log(data);
    //     handleSubmitRSVP(data);
      
    // };

    return (

        <>
        { eventData && 
          eventData.length>0 &&
          eventData.map( ( item, key) => (
          (
          <Fragment key={key}>

            <section className="page_title">
                <Container>
                    <h1>{item.title}</h1>
                </Container>
            </section>

            <section className="about_page event_detail_page">
                <Container>
                    <div className="event_detail_page">
                    { item.media && ( <figure><img src={item.media} alt={item.title} /></figure> )}

                       <div className="event_detail_info_part">
                       <div className="event_left"> 
                        <div className="event_detail_header">                       
                            <div className="event_detail_caption">
                                { item.publish_date && (
                                <span className="date"><img src={process.env.REACT_APP_PUBLIC_URL + "/assets/images/time.svg"} alt="Kanchana Gupta" />{item.publish_date}</span>
                                )}
                                { !!item.address && (
                                <span className="locationfix"><img src={ process.env.REACT_APP_PUBLIC_URL + "/assets/images/location.svg"} alt="Kanchana Gupta" />{item.address}</span>
                                )}
                            </div>
                            { !!item.address && (
                                <a href={ "https://www.google.com/maps/search/" + item.link_address } target="_blank" rel="noreferrer" className="readmore">Location on Map</a>
                            )}
                         </div>

                         <p dangerouslySetInnerHTML={{__html: item.description}}></p>

                        </div> 

                       <div className="event_detail_right">
                        { item.type==='upcoming' && (
                        <div title="Add to Calendar" className="addeventatc mt-3">
                            <i class="fa fa-plus"></i> Calendar
                            <span className="start">{item.start_date}</span>
                            <span className="end">{ item.end_date!=='' ? item.end_date : item.start_date }</span>
                            <span className="timezone">{item.timezone}</span>
                            <span className="title">{item.title}</span>
                            <span className="description">{decode(item.description, {level: 'html5'}) }</span>
                            <span className="location">{item.address}</span>
                        </div>   
                        ) } 
                       {/* <a href="https://www.addevent.com/dir/?client=aHlEVQojgzPGAfgTvmTC141486&start=12%2F20%2F2021+09%3A00+AM&end=12%2F20%2F2021+11%3A00+AM&title=Title+of+the+event&description=Description+of+the+event&location=1600+Amphitheatre+Pkwy%2C+Mountain+View%2C+CA+94043&timezone=America%2FLos_Angeles"><h5>Add to Calendar</h5></a>
                            <ul>
                               <li><a href="/#">iCalendar</a></li>
                               <li><a href="/#">Google</a></li>
                               <li><a href="/#">Outlook</a></li>
                               <li><a href="/#">Outlook Online</a></li>
                               <li><a href="/#">Yahoo</a></li>
                            </ul> */}

                            <a href="#"
                            onClick={handleShow}
                            className="ms-auto btn outline_btn btn_white mt-3 rsvp_btn"
                            >
                                RSVP
                            </a>
                            <div className="social">
                                <h6>Share:</h6>    

                                <TwitterShareButton url={window.location.href} title={item.title}>
                                <a href="/#"><img src={process.env.REACT_APP_PUBLIC_URL + "/assets/images/twiter.svg"} alt="Kanchana Gupta" /></a>
                                </TwitterShareButton>

                                <FacebookShareButton url={window.location.href} quote={item.title}>
                                <a href="/#"><img src={process.env.REACT_APP_PUBLIC_URL + "/assets/images/facebook.svg"} alt="Kanchana Gupta" /></a>
                                </FacebookShareButton>

                                <LinkedinShareButton url={window.location.href} title={item.title} >
                                <a href="/#"><img src={process.env.REACT_APP_PUBLIC_URL + "/assets/images/linkdin.svg"} alt="Kanchana Gupta" /></a>
                                </LinkedinShareButton>
                                
                                {/* <a href={"https://www.instagram.com/sharer.php?u=" + window.location.href} ><img src={process.env.REACT_APP_PUBLIC_URL + "/assets/images/instagrm.png"} alt="Kanchana Gupta" /></a> */}

                                <EmailShareButton url={window.location.href} title={item.title}>
                                <a href="/#"><img src={process.env.REACT_APP_PUBLIC_URL + "/assets/images/email.png"} alt="Kanchana Gupta" /></a>
                                </EmailShareButton>

                                <img src={process.env.REACT_APP_PUBLIC_URL + "/assets/images/link.png"} alt="Kanchana Gupta" className="link_copy" onClick={copy} />
                            </div>

                       </div>

                         </div>
                    </div>
                </Container>                    
            </section>

            <Modal
            show={show}
            onHide={handleClose}
            className="rsvp_modal"
            centered
            >
                <Modal.Header closeButton className="pt-0 pb-0">RSVP </Modal.Header>
                <Modal.Body className="arrow_icon thumbnail">
                    <Form onSubmit={handleSubmit(onSubmit)}>

                    <Form.Group controlId="rsvp_full_name" className='mb-3'>
                    <Form.Label>Name*</Form.Label>
                    <Form.Control
                        type="text"
                        className="form-control"
                        {...register("full_name")}
                        min="3"
                        required
                    />
                    </Form.Group>

                    <Form.Group controlId="rsvp_email" className='mb-3'>
                    <Form.Label>Email*</Form.Label>
                    <Form.Control
                        type="email"
                        className="form-control"
                        {...register("email")}
                        required
                    />
                    </Form.Group>
                        <Button
                            type="submit"
                            className="btn btn_submit ml-2"
                            align="center"
                            disabled={getLoader}
                          >
                            { getLoader ? "Submitting.." : "Submit"}
                          </Button>
                    </Form>
                </Modal.Body>
            </Modal>
            </Fragment>
    ) )) }


        </>
    )
}
export default Eventsdetail;