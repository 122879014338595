import * as types from "./actionsTypes";
import { agent } from "../utils/agent";
import toggleNetworkRequestStatus from "./toggleNetworkRequestStatus";
import * as API from "../utils/apiPath";

export function loadNewsDataSuccess(newsData) {
    return { type: types.LOAD_NEWS_DATA_SUCCESS, newsData };
}

export function loadNewsData(slug) {
    return async function(dispatch){
        dispatch(toggleNetworkRequestStatus(true));
        if(!!slug){
            await agent
            .post(API.NEWS_BY_SLUG_DATA, { slug: slug })
            .then((response) => {
                if(response.status === 200 || response.status === 201){
                    // console.log(response.data.data);
                    dispatch(loadNewsDataSuccess(response.data.data));
                }
            })
            .catch((error) => {
                dispatch(toggleNetworkRequestStatus(false));
                throw error;
            })
        }
        else{
        await agent
            .get(API.NEWS_DATA, {})
            .then((response) => {
                if(response.status === 200 || response.status === 201){
                    // console.log(response.data.data);
                    dispatch(loadNewsDataSuccess(response.data.data));
                }
            })
            .catch((error) => {
                dispatch(toggleNetworkRequestStatus(false));
                throw error;
            })
        }
    }
}
