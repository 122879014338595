import * as types from '../actions/actionsTypes';
import initialState from './initialState';

export default function soloShowReducer(state = initialState.soloShowData, action){
    switch(action.type) {
        case types.LOAD_SOLO_SHOW_DATA_SUCCESS:
            return action.soloShowData;
        default:
            return state;
    }
}