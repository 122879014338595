import * as types from "./actionsTypes";
import { agent } from "../utils/agent";
import toggleNetworkRequestStatus from "./toggleNetworkRequestStatus";
import * as API from "../utils/apiPath";

export function loadCVCategoryDataSuccess(cvCategoryData) {
    return { type: types.LOAD_CV_CATEGORY_DATA_SUCCESS, cvCategoryData };
}

export function loadCVDataSuccess(cVData) {
    return { type: types.LOAD_CV_DATA_SUCCESS, cVData };
}

export function loadCVCategoryData() {
    return async function(dispatch){
        dispatch(toggleNetworkRequestStatus(true));
        await agent
            .get(API.CV_CATEGORY_DATA, {})
            .then((response) => {
                if(response.status === 200 || response.status === 201){
                    // console.log(response.data.data);
                    dispatch(loadCVCategoryDataSuccess(response.data.data));
                }
            })
            .catch((error) => {
                dispatch(toggleNetworkRequestStatus(false));
                throw error;
            })
    }
}

export function loadCVData(slug) {
    return async function(dispatch){
        dispatch(toggleNetworkRequestStatus(true));
        
        if(!!slug){
            await agent
                .post(API.CV_BY_SLUG_DATA, { slug: slug })
                .then((response) => {
                    if(response.status === 200 || response.status === 201){
                        // console.log(response.data.data);
                        dispatch(loadCVDataSuccess(response.data.data));
                    }
                })
                .catch((error) => {
                    dispatch(toggleNetworkRequestStatus(false));
                    throw error;
                })
        }
        else{
            await agent
                .get(API.CV_DATA, {})
                .then((response) => {
                    if(response.status === 200 || response.status === 201){
                        // console.log(response.data.data);
                        dispatch(loadCVDataSuccess(response.data.data));
                    }
                })
                .catch((error) => {
                    dispatch(toggleNetworkRequestStatus(false));
                    throw error;
                })
        }
    }
}
