import * as types from '../actions/actionsTypes';
import initialState from './initialState';

export default function publicationDetailReducer(state = initialState.publicationDetailData, action){
    switch(action.type) {
        case types.LOAD_PUBLICATION_DETAIL_DATA_SUCCESS:
            return action.publicationDetailData;
        default:
            return state;
    }
}