import * as types from "./actionsTypes";
import { agent } from "../utils/agent";
import toggleNetworkRequestStatus from "./toggleNetworkRequestStatus";
import * as API from "../utils/apiPath";

export function loadPublicationDataSuccess(publicationData) {
    return { type: types.LOAD_PUBLICATION_DATA_SUCCESS, publicationData };
}

export function loadPublicationDetailDataSuccess(publicationDetailData) {
    return { type: types.LOAD_PUBLICATION_DETAIL_DATA_SUCCESS, publicationDetailData };
}

export function loadPublicationData(slug) {
    return async function(dispatch){
        dispatch(toggleNetworkRequestStatus(true));
        if(!!slug){
            await agent
                .post(API.PUBLICATION_BY_SLUG_DATA, { slug: slug })
                .then((response) => {
                    if(response.status === 200 || response.status === 201){
                        // console.log(response.data.data);
                        dispatch(loadPublicationDataSuccess(response.data.data));
                    }
                })
                .catch((error) => {
                    dispatch(toggleNetworkRequestStatus(false));
                    throw error;
                })
        }
        else{
            await agent
            .get(API.PUBLICATION_DATA, {})
            .then((response) => {
                if(response.status === 200 || response.status === 201){
                    // console.log(response.data.data);
                    dispatch(loadPublicationDataSuccess(response.data.data));
                }
            })
            .catch((error) => {
                dispatch(toggleNetworkRequestStatus(false));
                throw error;
            })
        }
    }
}

export function loadPublicationDetailData(slug) {
    return async function(dispatch){
        dispatch(toggleNetworkRequestStatus(true));
        await agent
            .post(API.PUBLICATION_DETAIL_BY_SLUG_DATA, { slug: slug })
            .then((response) => {
                if(response.status === 200 || response.status === 201){
                    // console.log(response.data.data);
                    dispatch(loadPublicationDetailDataSuccess(response.data.data));
                }
            })
            .catch((error) => {
                dispatch(toggleNetworkRequestStatus(false));
                throw error;
            })
    }
}
