import { notice, check, toast } from "react-interaction";

export const setToaster = (value) => {
  toast(value, { time: 2000 });
};

export const checkConform = async (callback, message) => {
  try {
    const isConfirmed = await check(message, {
      okStyle: {
        backgroundColor: "#0078ff",
        color: "#fff",
      },
      contentClassName: "account-logout",
      contentStyle: {
        width: 600,
      },
      okText: "Yes",
      cancelClassName: "my-check-cancel",
      cancelStyle: {
        backgroundColor: "#ccc",
        color: "#fff",
      },
      cancelText: "No",
    });

    if (isConfirmed) {
      callback();
    }
  } catch (error) {
    console.log(error.message);
  }
};

export const showToaster = (value) => {
  notice(value, {
    time: 4000,
    okStyle: {
      backgroundColor: "#00A5B3",
      fontSize: 18,
      color: "#fff",
    },

    okText: "OK",
  });
};

export const showToasterWithOk = (value) => {
  notice(value, {
    time: 4000,
    okStyle: {
      backgroundColor: "#0e81e4",
      fontSize: 18,
      color: "#fff",
    },
    okText: "OK",
  });
};

export const showMessageBox = (value) => {
  notice(value, {
    okStyle: {
      backgroundColor: "#0e81e4",
      fontSize: 18,
      color: "#fff",
    },
    okText: "OK",
  });
};

export const renderTitle = (title) => {
  if (!title) {
    return "";
  } else {
    var str = title;
    var n = str.length;
    if (n === 0) {
      return "";
    } else {
      var res = str.slice(0, 30);
      var dot = "";
      if (n > 30) {
        dot = "...";
      }
      return res + dot;
    }
  }
};

export const catchError = (response) => {
  if (response instanceof Error) {
    throw new Error(response.response.data.message);
  }
};

export const UCWords = (title) => {
  if (!title) {
    return "";
  } else {
    // split string on spaces
    var str = title;
    var arrStr = str.split(" ");

    var strOut = [];

    for (var i = 0; i < arrStr.length; i++) {
      // split string
      var firstChar = arrStr[i].substring(0, 1);
      var remainChar = arrStr[i].substring(1);

      // convert case
      firstChar = firstChar.toUpperCase();
      remainChar = remainChar.toLowerCase();

      strOut += firstChar + remainChar + " ";
    }

    // return string, but drop the last space
    return strOut.substring(0, strOut.length - 1);
  }
};

export const showErrorToaster = (value) => {
  notice(value, {
      time: 4000,
      okStyle: {
      backgroundColor: "#ef4343",
      fontSize: 18,
      color: "#fff",
      },

      okText: "OK",
  });
};
export const format= (time) => {
  let seconds = time % 60;
  let minutes = Math.floor(time / 60);
  minutes = minutes.toString().length === 1 ? "0" + minutes : minutes;
  seconds = seconds.toString().length === 1 ? "0" + seconds : seconds;
  return minutes + ':' + seconds;
  
  
}
export const checkSpace = function (aa) {
  
  if (aa.startsWith(' ') || aa.endsWith(' ')) {
    return false;
  } else {
    return true;
  }
  
};

export const checkMobileNumber = (event) => {

    var value = event.target.value;
    var k = event.keyCode;
    if (k === 9) {
    return true;
    }
    if (parseInt(event.key) === 0 && value.length === 0) {
    event.preventDefault();
    return false;
    } else if (k !== 8) {
        if ((k < 48 || k > 57) && (k < 96 || k > 105)) {
        event.preventDefault();
        return false;
        }
    }
};

// export const buildFormData = function (formData, data, parentKey) {
//   if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
//     Object.keys(data).forEach(key => {
//       buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
//     });
//   } else {
//     const value = data == null ? '' : data;

//     if(data.pan_image){
//       const selectedPanFile = data.pan_image;
//       formData.append('pan_image', selectedPanFile, selectedPanFile.name);
//     }

//     if(data.institute_image){
//       const selectedInstituteImageFile = data.institute_image;
//       formData.append('institute_image', selectedInstituteImageFile, selectedInstituteImageFile.name);
//     }

//     formData.append(parentKey, value);
//   }
// }

export const GetJoiningdate = (date) => {
    
    const mydate = new Date(date);
   
    const year = mydate.getFullYear();

    //console.log('Created date======>',month,year);
    if(year){
      return year;
    }else{
      return "";
    }
    
};