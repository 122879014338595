import * as types from '../actions/actionsTypes';
import initialState from './initialState';

export default function publicationReducer(state = initialState.publicationData, action){
    switch(action.type) {
        case types.LOAD_PUBLICATION_DATA_SUCCESS:
            return action.publicationData;
        default:
            return state;
    }
}