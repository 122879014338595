import React, { useEffect, Fragment } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Container } from "react-bootstrap"; 
import { loadPublicationDetailData } from "../../actions/publicationActions";

const Publicationdetail = (props) => {
    
    const slug = props.match.params.slug;
    
    // console.log('slug',slug);

    const dispatch = useDispatch();
    useEffect(() => {
    dispatch(loadPublicationDetailData(slug));
    }, [dispatch, slug]);
    
    const {
      publicationDetailData,
    } = useSelector((state) => ({
      publicationDetailData: state.publicationDetailData,
    }));
    
    // console.log('publicationDetailData',publicationDetailData);

    return (

        <>
        { publicationDetailData && 
          (
          <Fragment>

            <section className="page_title">
                <Container>
                    <h1>{publicationDetailData.title}</h1>
                </Container>
            </section>

            <section className="about_page event_detail_page">
                <Container>
                    <div className="event_detail_page">
                    { publicationDetailData.media && ( <figure><img src={publicationDetailData.media} alt={publicationDetailData.title} /></figure> )}

                       <div className="event_detail_info_part">
                       <div className="news_left event_left"> 
                        <div className="event_detail_header">    
                            <div className="link_cat">
                            { publicationDetailData.publication_type!=='no_link' && (
                            <h5>
                                <a href={publicationDetailData.media_file ? publicationDetailData.media_file : publicationDetailData.extenal_link} rel="noreferrer" target="_blank">
                                Link to Catalogue{" "}
                                </a>
                            </h5>
                            )}
                            </div>                   
                            <div className="event_detail_caption">
                                { publicationDetailData.publication_date && (
                                <span className="date"><img src={process.env.REACT_APP_PUBLIC_URL + "/assets/images/time.svg"} alt="Kanchana Gupta" />{publicationDetailData.publication_date}</span>
                                )}
                            </div>
                         </div>

                         <p dangerouslySetInnerHTML={{__html: publicationDetailData.description}}></p>

                        </div> 

                         </div>
                    </div>
                </Container>                    
            </section>

            </Fragment>
    ) }


        </>
    )
}
export default Publicationdetail;