import React, { useEffect, useState, Fragment } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Container } from "react-bootstrap";
// import { Link } from 'react-router-dom';
import Slider from "react-slick";
import { loadOtherShowData } from "../../actions/otherShowActions";

const SoloShowDetails = (props) => {

  const slug = props.match.params.slug;

  // console.log('slug',slug);

  const dispatch = useDispatch();
  useEffect(() => {
  dispatch(loadOtherShowData(slug));
  }, [dispatch, slug]);

  const {
      otherShowData,
  } = useSelector((state) => ({
    otherShowData: state.otherShowData,
  }));
  
  // console.log('otherShowData', otherShowData);

  const [getGallery, setGallery] = useState({});
  const [getDetails, setDetails] = useState([]);
  
  useEffect(() => {
    
    otherShowData && 
    otherShowData.length>0 && 
    otherShowData.map( ( item, key ) => (
        key === 0 && (
        setDetails(item)
        )
      ))
      
    }, [otherShowData]);

  useEffect(() => {
      setGallery(getDetails.images);
      document.title=getDetails.title + ' | Kanchana Gupta';
    }, [getDetails]);

      // console.log('getGallery',getGallery);

  const gallery_slider = {
    customPaging: function (i) {
      // console.log(i);
      if(getGallery && getGallery.length > 0 && getGallery[i].media){
        return (
          <a href="/#">
          <img src={getGallery[i].media} alt={getDetails.title} />
          </a>
        );
      }
      else {
        return (
          <a href="/#">
          <img src={getDetails.media} alt={getDetails.title} />
          </a>
        );
      }
    },
    dots: true,
    dotsClass: "slick-dots slick-thumb",
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <>
    { otherShowData && 
      otherShowData.length>0 &&
      otherShowData.map( ( item, key) => (
      (
      <Fragment key={key}>
      <section className="page_title">
        <Container>
          <div className="d-flex align-items-center">
            <h1>{item.title}</h1>
          </div>
        </Container>
      </section>
      <div className="work_detail_wrap">
        <Container>
          <div className="border-bottom pb-3 mb-3 pb-md-4 mb-md-4">
            <div className="link_cat">
              {/* <h5>
                <span>In:</span> Other Shows
              </h5>
              <h5>
                <span>By:</span> Kanchana Gupta
              </h5> */}
              { item.cate_link==='Yes' && (
              <h5>
                <a href={item.extenal_link} target="_blank" rel="noreferrer">
                  Link to Catalogue{" "}
                </a>
              </h5>
              )}
            </div>
            <div className="date_locate">
              { !!item.publish_date && (
              <span className="date">
                <img src={process.env.REACT_APP_PUBLIC_URL + "/assets/images/time.svg"} alt="" />
                {item.publish_date}
              </span>
              )}
              { !!item.address && (
              <span className="locationfix">
                <img src={ process.env.REACT_APP_PUBLIC_URL + "/assets/images/location.svg"} alt="" />
                {item.address}
              </span>
              )}
            </div>
          </div>
          <div className="text-gray mb-4">
            <br />
            <p dangerouslySetInnerHTML={{__html: item.description}}></p>
          </div>
          <div className="arrow_icon solo_slider thumbnail gray_nav">
            <Slider {...gallery_slider}>
              {
                item.images &&
                item.images.map( (img_item, key) => (
              <div key={key}>
                <img src={img_item.media} alt={item.title} />
              </div>
              ) ) }
            </Slider>
          </div>
        </Container>
        <div className="detail_next_prev">
          <Container>
            <div className="d-flex align-items-center">
              {
                item.previous && (
                  <a href={ process.env.REACT_APP_PUBLIC_URL + "/other-show-details/" + item.previous} className="">
                <img src={process.env.REACT_APP_PUBLIC_URL + "/assets/images/prev.svg"} className="me-2" alt="" />
                Previous
              </a>
              )}
              {
                item.next && (
                  <a href={ process.env.REACT_APP_PUBLIC_URL + "/other-show-details/" + item.next} className="ms-auto">
                Next{" "}
                <img
                  src={process.env.REACT_APP_PUBLIC_URL + "/assets/images/arrow.svg"}
                  className="ms-2"
                  alt=""
                />
              </a>
              )}
            </div>
          </Container>
        </div>
      </div>
      </Fragment>
    ) )) }
    </>
  );
};
export default SoloShowDetails;
