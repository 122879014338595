import * as types from '../actions/actionsTypes';
import initialState from './initialState';

export default function aboutUsReducer(state = initialState.aboutUsData, action){
    switch(action.type) {
        case types.LOAD_ABOUT_US_DATA_SUCCESS:
            return action.aboutUsData;
        default:
            return state;
    }
}