import * as types from "./actionsTypes";
import { agent } from "../utils/agent";
import toggleNetworkRequestStatus from "./toggleNetworkRequestStatus";
import * as API from "../utils/apiPath";

export function loadVideoDataSuccess(videoData) {
    return { type: types.LOAD_VIDEO_DATA_SUCCESS, videoData };
}

export function loadVideoData(ytdId) {
    return async function(dispatch){
        dispatch(toggleNetworkRequestStatus(true));
        if(!!ytdId){
            await agent
                .post(API.VIDEO_BY_YTD_ID_DATA, { ytdId: ytdId })
                .then((response) => {
                    if(response.status === 200 || response.status === 201){
                        // console.log(response.data.data);
                        dispatch(loadVideoDataSuccess(response.data.data));
                    }
                })
                .catch((error) => {
                    dispatch(toggleNetworkRequestStatus(false));
                    throw error;
                })
        }
        else{
            await agent
            .get(API.VIDEO_DATA, {})
            .then((response) => {
                if(response.status === 200 || response.status === 201){
                    // console.log(response.data.data);
                    dispatch(loadVideoDataSuccess(response.data.data));
                }
            })
            .catch((error) => {
                dispatch(toggleNetworkRequestStatus(false));
                throw error;
            })
        }
    }
}
