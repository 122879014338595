export const NETWORK_REQUEST_STATUS = "NETWORK_REQUEST_STATUS";
export const SUBMITTING_REQUEST_STATUS = "SUBMITTING_REQUEST_STATUS";
export const LOAD_HOME_PAGE_DATA_SUCCESS = "LOAD_HOME_PAGE_DATA_SUCCESS";
export const LOADED_USER_INFO_SUCCESS = "LOADED_USER_INFO_SUCCESS";
export const LOADED_USER_AUTH_SUCCESS = "LOADED_USER_AUTH_SUCCESS";
export const LOADED_USER_PARAM_SUCCESS = "LOADED_USER_PARAM_SUCCESS";
export const LOAD_EVENT_DATA_SUCCESS = "LOAD_EVENT_DATA_SUCCESS";
export const LOAD_NEWS_DATA_SUCCESS = "LOAD_NEWS_DATA_SUCCESS";
export const LOAD_ABOUT_US_DATA_SUCCESS = "LOAD_ABOUT_US_DATA_SUCCESS";
export const LOAD_CV_CATEGORY_DATA_SUCCESS = "LOAD_CV_CATEGORY_DATA_SUCCESS";
export const LOAD_WORK_DATA_SUCCESS = "LOAD_WORK_DATA_SUCCESS";
export const LOAD_PUBLICATION_DATA_SUCCESS = "LOAD_PUBLICATION_DATA_SUCCESS";
export const LOAD_PUBLICATION_DETAIL_DATA_SUCCESS = "LOAD_PUBLICATION_DETAIL_DATA_SUCCESS";
export const LOAD_VIDEO_DATA_SUCCESS = "LOAD_VIDEO_DATA_SUCCESS";
export const LOAD_MEDIA_DATA_SUCCESS = "LOAD_MEDIA_DATA_SUCCESS";
export const LOAD_MEDIA_GALLERY_DATA_SUCCESS = "LOAD_MEDIA_GALLERY_DATA_SUCCESS";
export const LOAD_OTHER_SHOW_DATA_SUCCESS = "LOAD_OTHER_SHOW_DATA_SUCCESS";
export const LOAD_SOLO_SHOW_DATA_SUCCESS = "LOAD_SOLO_SHOW_DATA_SUCCESS";
export const LOAD_CV_DATA_SUCCESS = "LOAD_CV_DATA_SUCCESS";
export const LOAD_SETTING_DATA_SUCCESS = "LOAD_SETTING_DATA_SUCCESS";
export const LOAD_SIDEBAR_DATA_SUCCESS = "LOAD_SIDEBAR_DATA_SUCCESS";