import * as types from '../actions/actionsTypes';
import initialState from './initialState';

export default function mediaGalleryReducer(state = initialState.mediaGalleryData, action){
    switch(action.type) {
        case types.LOAD_MEDIA_GALLERY_DATA_SUCCESS:
            return action.mediaGalleryData;
        default:
            return state;
    }
}