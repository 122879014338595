import React, { StrictMode } from "react";
import ReactDOM from "react-dom";
import App from "./App";
import SimpleReactLightbox from 'simple-react-lightbox'
// import reportWebVitals from "./reportWebVitals";

import { Provider } from "react-redux";
import storeConfig from "./store";
import { PersistGate } from "redux-persist/integration/react";
import { BrowserRouter } from "react-router-dom";
const { REACT_APP_BASE_NAME } = process.env;
const { store, persistor } = storeConfig;

ReactDOM.render(
  <StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter basename={REACT_APP_BASE_NAME}>
        <SimpleReactLightbox>
          <App />
          </SimpleReactLightbox>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
