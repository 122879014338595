import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import { loadVideoData } from "../../actions/videoActions";
import VideoModal from "./videoModal";

const Videos = (props) => {
  const ytdId = props.match.params.ytdId;

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loadVideoData(ytdId));
  }, [dispatch, ytdId]);

  const { videoData } = useSelector((state) => ({
    videoData: state.videoData,
  }));

  const [userData, setUserData] = useState({});
  const [videoShow, setVideoShow] = useState(false);

  const replyVideoModal = (e, yt_video_id, title) => {
    e.preventDefault();
    setVideoShow(true);
    setUserData({
      yt_video_id: yt_video_id,
      title: title,
    });
  };

  const extractVideoId = (url) => {
    try {
      const urlObj = new URL(url);
      return urlObj.searchParams.get("v");
    } catch (error) {
      console.error("Invalid URL:", url, error);
      return "";
    }
  };

  return (
    <>
      <section className="page_title">
        <Container>
          <h1>Videos</h1>
        </Container>
      </section>

      <section className="about_page">
        <Container>
          <div className="main_contnat">
            <div className="page_main_outer">
              {/* <Sidebar module={'Videos'} /> */}

              {/* <div className="pagemain_right"> */}
              <div className="videos_page">
                {/* <h2 className="mb-3">Videos</h2> */}
                <Row className="events_listing-outer">
                  {videoData &&
                    videoData.length > 0 &&
                    videoData.map((item, key) => (
                      <Col md={4} sm={6} key={key}>
                        <div className="event_listing verticle_grid" key={key}>
                          <figure>
                            <img alt="Kanchana Gupta" src={extractVideoId(item.external_link) ? `https://img.youtube.com/vi/${extractVideoId(item.external_link)}/hqdefault.jpg` : ""} />

                            <img
                              alt="Kanchana Gupta"
                              className="video_icon"
                              src={process.env.REACT_APP_PUBLIC_URL + "/assets/images/videoicon.png"}
                              onClick={(e) => {
                                replyVideoModal(e, item.external_link, item.title);
                              }}
                            />
                          </figure>
                          <h3
                            className="video_list_info"
                            onClick={(e) => {
                              replyVideoModal(e, item.external_link, item.title);
                            }}
                          >
                            {item.title}
                          </h3>
                          {/* <span className="date"><img alt="Kanchana Gupta" src={process.env.REACT_APP_PUBLIC_URL + "/assets/images/time.svg"} />3 April, 2020 </span> */}
                        </div>
                      </Col>
                    ))}
                </Row>
              </div>

              {/* </div> */}
            </div>
          </div>
        </Container>
      </section>

      <VideoModal
        show={videoShow}
        userdata={userData}
        onHide={() => setVideoShow(false)}
        // forumData={() => actions.loadForumData(userInfo.id)}
        centered
        size="xl"
        className="mentor_modal"
      />
    </>
  );
};
export default Videos;
