import * as types from "./actionsTypes";
import { agent } from "../utils/agent";
import toggleNetworkRequestStatus from "./toggleNetworkRequestStatus";
import * as API from "../utils/apiPath";
import { showToasterWithOk, showErrorToaster } from "../utils/helpers";

export function loadHomePageDataSuccess(homePageData) {
    return { type: types.LOAD_HOME_PAGE_DATA_SUCCESS, homePageData };
}

export function loadSettingDataSuccess(settingsData) {
    return { type: types.LOAD_SETTING_DATA_SUCCESS, settingsData };
}

export function loadSidebarDataSuccess(sidebarData) {
    return { type: types.LOAD_SIDEBAR_DATA_SUCCESS, sidebarData };
}

export function loadHomePageData() {
    return async function(dispatch){
        dispatch(toggleNetworkRequestStatus(true));
        await agent
            .get(API.HOME_PAGE_DATA, {})
            .then((response) => {
                if(response.status === 200 || response.status === 201){
                    // console.log(response.data.data);
                    dispatch(loadHomePageDataSuccess(response.data.data));
                }
            })
            .catch((error) => {
                dispatch(toggleNetworkRequestStatus(false));
                throw error;
            })
    }
}

export function saveNewletter(params) {
    return async function (dispatch) {
      dispatch(toggleNetworkRequestStatus(true));
      await agent
        .post(API.SUBMIT_NEWSLETTER, params)
        .then((response) => {
            console.log(response);
            if(response.data.response===true){
                showToasterWithOk(response.data.message);
                document.getElementById("newsletter_email").value = "";
            }
            else{
                showErrorToaster(response.data.message);
            }
        })
        .catch((error) => {
          throw error;
        });
    };
}

export function loadSettingData() {
    return async function(dispatch){
        dispatch(toggleNetworkRequestStatus(true));
        await agent
            .get(API.SETTING_DATA, {})
            .then((response) => {
                if(response.status === 200 || response.status === 201){
                    // console.log(response.data.data);
                    dispatch(loadSettingDataSuccess(response.data.data));
                }
            })
            .catch((error) => {
                dispatch(toggleNetworkRequestStatus(false));
                throw error;
            })
    }
}

export function loadSidebarData() {
    return async function(dispatch){
        dispatch(toggleNetworkRequestStatus(true));
        await agent
            .get(API.SIDEBAR_DATA, {})
            .then((response) => {
                if(response.status === 200 || response.status === 201){
                    // console.log(response.data.data);
                    dispatch(loadSidebarDataSuccess(response.data.data));
                }
            })
            .catch((error) => {
                dispatch(toggleNetworkRequestStatus(false));
                throw error;
            })
    }
}