import React, { useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Link } from 'react-router-dom';
import { Col, Row, Container} from "react-bootstrap";
import Slider from "react-slick";
import { loadHomePageData } from "../../actions/homePageActions";
 

const Index = () => {

      const dispatch = useDispatch();
      useEffect(() => {
      dispatch(loadHomePageData());
      }, [dispatch]);

      const {
        homePageData,
      } = useSelector((state) => ({
        homePageData: state.homePageData,
      }));
      

      const settings = {
        dots: false,
        nav:true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: true,
        autoplay:true,
        autoplaySpeed:2000,
      };

    //   console.log('homePageData', homePageData);
      

    return (

        <> 
        <section className="topbanner_slider">
            <Container>
            <Slider {...settings}>
                { 
                    homePageData.slider && 
                    homePageData.slider.length>0 &&
                    homePageData.slider.map((item, key) => (
                    <div className="banner_slider" key={key}>
                        <Link to={"/#" }>
                            { item.media_type === 'image' ? (
                            <img alt={item.alt_text ? item.alt_text : "Kanchana Gupta" } src={item.media} />
                            ) : (
                            <video
                                src={item.media}
                                controls
                                controlsList="nodownload"
                                autoplay muted loop id="myVideo"
                            ></video>
                            )}
                        </Link>
                    </div>
                ))}
                {/* <div className="banner_slider"><Link to={"/#" }><img alt="Kanchana Gupta" src="./assets/images/banner.jpg" /><span className="pulse video_player"><img alt="Kanchana Gupta" src="./assets/images/video.png" /></span></Link></div>
                <div className="banner_slider"><Link to={"/#" }><img alt="Kanchana Gupta" src="./assets/images/banner.jpg" /><span className="pulse video_player"><img alt="Kanchana Gupta" src="./assets/images/video.png" /></span></Link></div>                   
                <div className="banner_slider"><Link to={"/#" }><img alt="Kanchana Gupta" src="./assets/images/banner.jpg" /><span className="pulse video_player"><img alt="Kanchana Gupta" src="./assets/images/video.png" /></span></Link></div>                   
                <div className="banner_slider"><Link to={"/#" }><img alt="Kanchana Gupta" src="./assets/images/banner.jpg" /><span className="pulse video_player"><img alt="Kanchana Gupta" src="./assets/images/video.png" /></span></Link></div>                    */}
                </Slider> 
            </Container>
        </section>


        <section className="work_section">
            <Container>                
                    <div className="work_cols_outer">
                        <div className="section_title verticletype"><h3 className="home-module-heading">Works</h3></div>
                            <div className="large_grid_pic">
                            { 
                            homePageData.sorted_work && 
                            homePageData.sorted_work.length>0 &&
                            homePageData.sorted_work.map((item, key) => (
                                key===0 && (
                                    <div className="thumnail" key={key}>
                                        <figure><img alt={item.title} src={item.media} /></figure> 
                                        <Link to={"/work-detail/" + item.slug} className="btn outline_btn">Read More</Link>
                                    </div>
                                )
                            ))}
                            </div>
                            <div className="small_grid_pic">
                            { 
                            homePageData.sorted_work && 
                            homePageData.sorted_work.length>0 &&
                            homePageData.sorted_work.map((item, key) => (
                                key!==0 && (
                                    <div className="thumnail" key={key}> <figure><img alt={item.title} src={item.media} /><Link to={"/work-detail/" + item.slug} className="btn outline_btn">Read More</Link></figure></div>
                                )
                            ))}
                                {/* <div className="thumnail"><figure><img alt="Kanchana Gupta" src="./assets/images/smallthumb2.jpg" /><Link to={"/#" } className="btn outline_btn">Read More</Link></figure></div> */}
                            </div> 
                        </div>
                        <Link to={"/works" } className="btn outline_btn">Works</Link> 
            </Container>
        </section>


        <section className="latest_exh">
            <Container>
               <div className="latest_exh_list_outer">
                 <div className="section_title verticletype"><h3 className="home-module-heading padding-left-5">Latest Exhibitions</h3></div>
                    {/* { 
                        homePageData.solo_select && 
                        homePageData.solo_select.length>0 &&
                        homePageData.solo_select.map((item, key) => (
                        <div className="verticle_grid lg_verticle_list" key={key}>
                            <figure><img alt={item.title} src={item.media} /></figure>
                            <div className="list_info">
                                <h3><Link to={"/other-show-details/" + item.slug }>{item.title}</Link></h3>
                                { !!item.publish_date && ( <span className="date"><img alt="Kanchana Gupta" src={process.env.REACT_APP_PUBLIC_URL + "/assets/images/time.svg"} />{item.publish_date}</span> )}
                                <p dangerouslySetInnerHTML={{__html: item.description.slice(0,120)+ "..."}}></p>
                                <Link to={"/other-show-details/" + item.slug } className="readmore">Read more<img alt="Kanchana Gupta" src={process.env.REACT_APP_PUBLIC_URL + "/assets/images/arrow.svg"} /></Link>
                            </div> 
                        </div> 
                    ))}
                    { 
                        homePageData.other_solo_select && 
                        homePageData.other_solo_select.length>0 &&
                        homePageData.other_solo_select.map((item, key) => (
                        <div className="verticle_grid sm_verticle_list" key={key}>
                            <figure><img alt={item.title} src={item.media} /></figure>
                            <div className="list_info">
                                <h3><Link to={"/solo-show-details/" + item.slug }>{item.title}</Link></h3>
                                { !!item.publish_date && ( <span className="date"><img alt="Kanchana Gupta" src={process.env.REACT_APP_PUBLIC_URL + "/assets/images/time.svg"} />{item.publish_date}</span> )}
                                <p dangerouslySetInnerHTML={{__html: item.description.slice(0,120)+ "..."}}></p>
                                <Link to={"/solo-show-details/" + item.slug } className="readmore">Read more<img alt="Kanchana Gupta" src={process.env.REACT_APP_PUBLIC_URL + "/assets/images/arrow.svg"} /></Link>
                            </div> 
                        </div>
                    ))} */}
                    { 
                        homePageData.exhibition && 
                        homePageData.exhibition.length>0 &&
                        homePageData.exhibition.map((item, key) => (
                        <div className="verticle_grid lg_verticle_list" key={key}>
                            <figure>
                            <a href={ process.env.REACT_APP_PUBLIC_URL + "/" + item.exhibition + "-show-details/" + item.slug }><img alt={item.title} src={item.media} /></a>
                            </figure>
                            <div className="list_info" style={{padding: "24px 0px"}}>
                                <h3><a href={ process.env.REACT_APP_PUBLIC_URL + "/" + item.exhibition + "-show-details/" + item.slug }>{item.title}</a></h3>
                                { !!item.publish_date && ( <span className="date"><img alt="Kanchana Gupta" src={process.env.REACT_APP_PUBLIC_URL + "/assets/images/time.svg"} />{item.publish_date}</span> )}
                                <p dangerouslySetInnerHTML={{__html: item.short_description}}></p>
                                <a href={ process.env.REACT_APP_PUBLIC_URL + "/" + item.exhibition + "-show-details/" + item.slug } className="readmore">Read more</a>
                            </div> 
                        </div> 
                    ))}
                </div>
                <Link to="/solo-shows" className="btn outline_btn">Solo Shows</Link> 
                <Link to="/other-shows" className="btn outline_btn side_btn">Other Shows</Link> 

            </Container>
        </section>


        <section className="newsevent">
            <Container>
                <div className="event_list_outer">
                    <div className="section_title verticletype"><h3 className="home-module-heading">News and Events</h3></div>

                    { 
                        homePageData.events && 
                        homePageData.events.length>0 &&
                        homePageData.events.map((item, key) => (
                        <div className="horizantel_grid" key={key}>
                            <Row> 
                                <Col md={6}>
                                    <figure><a href={process.env.REACT_APP_PUBLIC_URL + "/event-detail/" + item.slug }><img alt={item.title} src={item.media} /></a></figure>
                                </Col> 

                                <Col md={6}> 
                                    <div className="list_info" style={{    paddingTop: "5px"}}>
                                        <h3><a href={process.env.REACT_APP_PUBLIC_URL + "/event-detail/" + item.slug }>{item.title}</a></h3>
                                        { !!item.publish_date && ( <span className="date"><img alt="Kanchana Gupta" src={process.env.REACT_APP_PUBLIC_URL + "/assets/images/time.svg"} />{item.publish_date}</span> )}
                                        <p dangerouslySetInnerHTML={{__html: item.short_description}}></p>
                                        <a href={process.env.REACT_APP_PUBLIC_URL + "/event-detail/" + item.slug } className="readmore">Read more</a>
                                    </div> 
                                </Col> 
                            </Row>
                        </div>
                    )) }

                    
                    { 
                        homePageData.news && 
                        homePageData.news.length>0 &&
                        homePageData.news.map((item, key) => (
                        <div className="horizantel_grid" key={key}>
                            <Row> 
                                <Col md={6}>
                                    <figure>
                                        <Link to={"/news-detail/" + item.slug }><img alt={item.title} src={item.media} /></Link>
                                    </figure>
                                </Col> 

                                <Col md={6}> 
                                    <div className="list_info">
                                        <h3><Link to={"/news-detail/" + item.slug }>{item.title}</Link></h3>
                                        { !!item.publish_date && ( <span className="date"><img alt="Kanchana Gupta" src={process.env.REACT_APP_PUBLIC_URL + "/assets/images/time.svg"} />{item.publish_date}</span> )}
                                        <p dangerouslySetInnerHTML={{__html: item.short_description}}></p>
                                        <Link to={"/news-detail/" + item.slug } className="readmore">Read more</Link>
                                    </div> 
                                </Col> 
                            </Row>
                        </div>
                    )) }    

                </div>
                
                <a href={process.env.REACT_APP_PUBLIC_URL + "/events/upcoming"} className="btn outline_btn">Events</a> 
                <Link to={"/news" } className="btn outline_btn side_btn">News</Link> 

                </Container>
            </section>


        </>
    )
}
export default Index;