import * as types from "./actionsTypes";
import { agent } from "../utils/agent";
import toggleNetworkRequestStatus from "./toggleNetworkRequestStatus";
import * as API from "../utils/apiPath";

export function loadOtherShowDataSuccess(otherShowData) {
    return { type: types.LOAD_OTHER_SHOW_DATA_SUCCESS, otherShowData };
}

export function loadOtherShowData(slug) {
    return async function(dispatch){
        dispatch(toggleNetworkRequestStatus(true));
        if(!!slug){
            await agent
            .post(API.OTHER_SHOW_BY_SLUG_DATA, { slug: slug })
            .then((response) => {
                if(response.status === 200 || response.status === 201){
                    // console.log(response.data.data);
                    dispatch(loadOtherShowDataSuccess(response.data.data));
                }
            })
            .catch((error) => {
                dispatch(toggleNetworkRequestStatus(false));
                throw error;
            })
        }
        else{
        await agent
            .get(API.OTHER_SHOW_DATA, {})
            .then((response) => {
                if(response.status === 200 || response.status === 201){
                    // console.log(response.data.data);
                    dispatch(loadOtherShowDataSuccess(response.data.data));
                }
            })
            .catch((error) => {
                dispatch(toggleNetworkRequestStatus(false));
                throw error;
            })
        }
    }
}
