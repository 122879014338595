import React, { useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Container } from "react-bootstrap";
import { loadMediaData, loadMediaGalleryData } from "../../actions/mediaActions";
import { SRLWrapper } from "simple-react-lightbox";
const Media = () => {

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(loadMediaData());
        dispatch(loadMediaGalleryData());
    }, [dispatch]);

    const {
        mediaData, mediaGalleryData,
    } = useSelector((state) => ({
      mediaData: state.mediaData,
      mediaGalleryData: state.mediaGalleryData,
    }));
    
    // console.log('mediaData', mediaData);
    // console.log('mediaGalleryData', mediaGalleryData);

    return (

        <>

            <section className="page_title">
                <Container>
                    <h1>Media</h1>
                </Container>
            </section>

            <section className="about_page media_page">
                <Container>
                    <h3>Online & Printed Media</h3>
                    <div className="media_link">
                        <ul>
                            {/* <li><a href="/#">Dohadwala, Durriya. “Kanchana Gupta Deconstructs the Representation of the Female Body in Indian Cinema”. </a><span>COBO Social</span></li> */}
                            {
                                mediaData &&
                                mediaData.length>0 &&
                                mediaData.map( ( item, key ) => (
                            <li key={key}><a href={item.external_link} rel="noreferrer" target="_blank">{item.title}</a></li>
                            ))}
                            
                        </ul>
                    </div>

                    {
                        mediaGalleryData &&
                        mediaGalleryData.length>0 && (
                    <div className="media_article">
                   
                        <SRLWrapper>             
                            <div className="media_inner">   
                            
                            {
                            mediaGalleryData.map( (item, key) => (       
                            <a href={item.media} key={key}><img alt={ !!item.alt_text ? item.alt_text : "Kanchana Gupta" } src={item.media} /></a> 
                            ))}

                            </div>
                        </SRLWrapper>
                       
                    </div>
                    ) }

                </Container>
            </section>



        </>
    )
}
export default Media;