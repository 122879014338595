import * as types from '../actions/actionsTypes';
import initialState from './initialState';

export default function otherShowReducer(state = initialState.otherShowData, action){
    switch(action.type) {
        case types.LOAD_OTHER_SHOW_DATA_SUCCESS:
            return action.otherShowData;
        default:
            return state;
    }
}