import HomePage from "../Pages/Home";
import About from "../Pages/About";
import Cv from "../Pages/Cv"; 
import Events from "../Pages/Events";
import EventsDetail from "../Pages/Events/detail";
import Works from "../Pages/Work";
import WorksDetails from "../Pages/Work/detail";
import Videos from "../Pages/Videos";
import SoloShows from "../Pages/SoloShows";
import SoloShowsDetail from "../Pages/SoloShows/details";
import OtherShows from "../Pages/OtherShows";
import OtherShowsDetail from "../Pages/OtherShows/details";
import Media from "../Pages/Media";
import Publication from "../Pages/Publication";
import PublicationDetail from "../Pages/Publication/detail";
import News from "../Pages/News";
import NewsDetail from "../Pages/News/detail";

export const RoutesPage = [
    { 
        path: "/", 
        component: HomePage,
        title: ''
    },
    { 
        path: "/about", 
        component: About,
        title: 'About'
    },
    { 
        path: "/cv", 
        component: Cv,
        title: 'Cv'
    },
    { 
        path: "/cv/:slug", 
        component: Cv,
        title: 'Cv'
    },
    { 
        path: "/works", 
        component: Works,
        title: 'Works'
    },
    { 
        path: "/works/:slug", 
        component: Works,
        title: 'Works'
    },
    { 
        path: "/work-detail/:slug", 
        component: WorksDetails,
        title: 'Work Detail'
    },
    { 
        path: "/solo-shows", 
        component: SoloShows,
        title: 'Solo Shows'
    },
    { 
        path: "/solo-show-details/:slug", 
        component: SoloShowsDetail,
        title: 'Solo Show Detail'
    },
    { 
        path: "/other-shows", 
        component: OtherShows,
        title: 'Other Shows'
    },
    { 
        path: "/other-shows/:slug", 
        component: OtherShows,
        title: 'Other Shows'
    },
    { 
        path: "/other-show-details/:slug", 
        component: OtherShowsDetail,
        title: 'Other Shows Detail'
    },
    { 
        path: "/media", 
        component: Media,
        title: 'Media'
    },
    { 
        path: "/publication", 
        component: Publication,
        title: 'Publication'
    },
    { 
        path: "/publication/:slug", 
        component: Publication,
        title: 'Publication'
    },
    { 
        path: "/publication-detail/:slug", 
        component: PublicationDetail,
        title: 'Publication Detail'
    },
    { 
        path: "/videos", 
        component: Videos,
        title: 'Videos'
    },
    { 
        path: "/videos/:ytdId", 
        component: Videos,
        title: 'Videos'
    },
    { 
        path: "/events/:type", 
        component: Events,
        title: 'Events'
    },
    { 
        path: "/event-detail/:slug", 
        component: EventsDetail,
        title: 'Event Detail'
    },
    { 
        path: "/news", 
        component: News,
        title: 'News'
    },
    { 
        path: "/news-detail/:slug", 
        component: NewsDetail,
        title: 'News Detail'
    }
]