import * as types from "./actionsTypes";
import { agent } from "../utils/agent";
import toggleNetworkRequestStatus from "./toggleNetworkRequestStatus";
import * as API from "../utils/apiPath";

export function loadAboutUsDataSuccess(aboutUsData) {
    return { type: types.LOAD_ABOUT_US_DATA_SUCCESS, aboutUsData };
}

export function loadAboutUsData() {
    return async function(dispatch){
        dispatch(toggleNetworkRequestStatus(true));
        await agent
            .get(API.ABOUT_US_DATA, {})
            .then((response) => {
                if(response.status === 200 || response.status === 201){
                    // console.log(response.data.data);
                    dispatch(loadAboutUsDataSuccess(response.data.data));
                }
            })
            .catch((error) => {
                dispatch(toggleNetworkRequestStatus(false));
                throw error;
            })
    }
}
