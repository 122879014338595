import * as types from '../actions/actionsTypes';
import initialState from './initialState';

export default function cvCategoryReducer(state = initialState.cvCategoryData, action){
    switch(action.type) {
        case types.LOAD_CV_CATEGORY_DATA_SUCCESS:
            return action.cvCategoryData;
        default:
            return state;
    }
}