import * as types from "./actionsTypes";
import { agent } from "../utils/agent";
import toggleNetworkRequestStatus from "./toggleNetworkRequestStatus";
import * as API from "../utils/apiPath";

export function loadMediaDataSuccess(mediaData) {
    return { type: types.LOAD_MEDIA_DATA_SUCCESS, mediaData };
}

export function loadMediaGalleryDataSuccess(mediaGalleryData) {
    return { type: types.LOAD_MEDIA_GALLERY_DATA_SUCCESS, mediaGalleryData };
}

export function loadMediaData() {
    return async function(dispatch){
        dispatch(toggleNetworkRequestStatus(true));
        await agent
            .get(API.MEDIA_DATA, {})
            .then((response) => {
                if(response.status === 200 || response.status === 201){
                    // console.log(response.data.data);
                    dispatch(loadMediaDataSuccess(response.data.data));
                }
            })
            .catch((error) => {
                dispatch(toggleNetworkRequestStatus(false));
                throw error;
            })
    }
}

export function loadMediaGalleryData() {
    return async function(dispatch){
        dispatch(toggleNetworkRequestStatus(true));
        await agent
            .get(API.MEDIA_GALLERY_DATA, {})
            .then((response) => {
                if(response.status === 200 || response.status === 201){
                    // console.log(response.data.data);
                    dispatch(loadMediaGalleryDataSuccess(response.data.data));
                }
            })
            .catch((error) => {
                dispatch(toggleNetworkRequestStatus(false));
                throw error;
            })
    }
}