import React, { lazy, Suspense, Fragment } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { RoutesPage } from "../Route/route";
import Loader from "../Component/Loader";
const Header = lazy(() => import("../Component/Header"));
const Footer = lazy(() => import("../Component/Footer"));

const RouteWithHeader = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => (
        <Fragment>
          <Header title={rest.title} />
          <Component {...props} title={rest.title} />
          <Footer />
        </Fragment>
      )}
    />
  );
};

const Index = () => {
  return (
    <Fragment>
      <Suspense fallback={<Loader />}>
        <Switch>
          {RoutesPage.map((route, index) => (
            <RouteWithHeader
              key={index}
              exact
              path={route.path}
              component={route.component}
              title={route.title}
            />
          ))}
          <Redirect to="/" />
        </Switch>
      </Suspense>
    </Fragment>
  );
};

export default Index;
