import * as types from '../actions/actionsTypes';
import initialState from './initialState';

export default function workReducer(state = initialState.workData, action){
    switch(action.type) {
        case types.LOAD_WORK_DATA_SUCCESS:
            return action.workData;
        default:
            return state;
    }
}