import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Link } from 'react-router-dom';
import { Col, Row, Modal, Container, Form, Button } from "react-bootstrap"; 
import { loadEventTypeData, handleSubmitRSVP } from "../../actions/eventActions";
import { setToaster } from "../../utils/helpers";
import { useForm } from 'react-hook-form';
import { decode } from 'html-entities';


const Events = (props) => {

    const event_type = props.match.params.type;

    const [getDetails, setDetails] = useState([]);
    const [show, setShow] = useState(false);
    const [getLoader, setLoader] = useState(false);
    const [getSlug, setSlug] = useState('');
    const { register, handleSubmit } = useForm();

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleEventShow = async (e, slug) => {
    //   e.preventDefault();
      setSlug(slug);
      setShow(true);
    }

    const dispatch = useDispatch();
    useEffect(() => {
    dispatch(loadEventTypeData(event_type));
    }, [dispatch, event_type]);
    
    const {
      eventData,
    } = useSelector((state) => ({
      eventData: state.eventData,
    }));
    
    // console.log('eventData',eventData);

    const onSubmit = (data) => {
        //   console.log('data',data);
        //   console.log('getSlug',getSlug);
            const request = {};
            if(!!data.full_name && !!data.email){
                request.full_name = data.full_name;
                request.email = data.email;
                request.slug = getSlug;
                // setFormError(false);
                setLoader(true);
                dispatch(handleSubmitRSVP(request,setShow,setLoader));
            }
            else{
            // setFormError(true);
            }
        }; 
    return (

        <>

            <section className="page_title">
                <Container>
                    <h1>Events</h1>
                </Container>
            </section>

            <section className="about_page page-content event-page">
                <Container>
                    <div className="event-wrapper">
                    <div className="event-tabs-wr">
                        <a href={ process.env.REACT_APP_PUBLIC_URL + "/events/ongoing" } className={ event_type==='ongoing' ? "active event-tabs" : "event-tabs"}>Current</a>
                        <a href={ process.env.REACT_APP_PUBLIC_URL + "/events/upcoming" } className={ event_type==='upcoming' ? "active event-tabs" : "event-tabs"}>Upcoming</a>
                        <Link to="/events/past" className={ event_type==='past' ? "active event-tabs" : "event-tabs"}>Past</Link>
                        </div>
                    <Row className="events_listing-outer onlyevent">

           
                        {
                            eventData &&
                            eventData.length>0 ? (

                            eventData.map( ( item, key ) => (
                            <Col lg={4} md={6} key={key}>
                                <div className="event_listing verticle_grid">
                                    <figure><a href={process.env.REACT_APP_PUBLIC_URL + "/event-detail/" + item.slug }><img alt={item.title} src={item.media} /></a></figure>
                                    <div className="list_info">
                                        <h3><a href={process.env.REACT_APP_PUBLIC_URL + "/event-detail/" + item.slug }>{item.title}</a></h3>
                                        { !!item.publish_date && (
                                        <span className="date"><img alt="Kanchana Gupta" src={process.env.REACT_APP_PUBLIC_URL + "/assets/images/time.svg"} />{item.publish_date}</span> )}
                                        <span className="locationfix"><img alt="Kanchana Gupta" src={process.env.REACT_APP_PUBLIC_URL + "/assets/images/location.svg"} />{item.address}</span>
                                        
                                        { (event_type==='upcoming' || event_type==='ongoing') && (
                                        <>
                                        <div title="Add to Calendar" className="addeventatc mt-3">
                                            <i className="fa fa-plus"></i> Calendar
                                            <span className="start">{item.start_date}</span>
                                            <span className="end">{ !!item.end_date ? item.end_date : item.start_date }</span>
                                            <span className="timezone">{item.timezone}</span>
                                            <span className="title">{item.title}</span>
                                            <span className="description">{decode(item.description, {level: 'html5'}) }</span>
                                            <span className="location">{item.address}</span>
                                        </div> 
                                        <a href="#"
                                        onClick={(e) => {handleEventShow(e, item.slug)}}
                                        className="ms-auto btn outline_btn btn_white mt-3 rsvp_btn"
                                        >
                                            RSVP
                                        </a>
                                        </>
                                        ) } 

                                        { !!item.short_description && ( <p>{item.short_description}</p> ) }

                                        <a href={process.env.REACT_APP_PUBLIC_URL + "/event-detail/" + item.slug } className="readmore">Read more</a>
                                    </div>
                                </div>
                            </Col>
                        )  )  ) : (
                            <>
                            <h3 align="center">No Data Found</h3>
                            </>
                        ) }

                    </Row>
                    </div>
                </Container>
            </section>


            <Modal
            show={show}
            onHide={handleClose}
            className="rsvp_modal"
            centered
            >
                <Modal.Header closeButton className="pt-0 pb-0">RSVP </Modal.Header>
                <Modal.Body className="arrow_icon thumbnail">
                    <Form onSubmit={handleSubmit(onSubmit)}>

                    <Form.Group controlId="rsvp_full_name" className='mb-3'>
                    <Form.Label>Name*</Form.Label>
                    <Form.Control
                        type="text"
                        className="form-control"
                        {...register("full_name")}
                        min="3"
                        required
                    />
                    </Form.Group>

                    <Form.Group controlId="rsvp_email" className='mb-3'>
                    <Form.Label>Email*</Form.Label>
                    <Form.Control
                        type="email"
                        className="form-control"
                        {...register("email")}
                        required
                    />
                    </Form.Group>
                        <Button
                            type="submit"
                            className="btn btn_submit ml-2"
                            align="center"
                            disabled={getLoader}
                          >
                            { getLoader ? "Submitting.." : "Submit"}
                          </Button>
                    </Form>
                </Modal.Body>
            </Modal>


        </>
    )
}
export default Events;