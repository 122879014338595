import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { loadSidebarData } from "../../actions/homePageActions";
import { Link, useLocation } from 'react-router-dom';
 

const Sidebar = (prop) => {

    const { module } = prop;
    // console.log('module',module);

    const dispatch = useDispatch();
    useEffect(() => {
    dispatch(loadSidebarData());
    }, [dispatch]);

    const {
      sidebarData,
    } = useSelector((state) => ({
      sidebarData: state.sidebarData,
    }));
    // console.log('sidebarData',sidebarData);

    let location = useLocation();
    const [ path_name, set_path_name ] = useState('') ;

    useEffect(() => {
        set_path_name(location.pathname);
    },[location]);
    // console.log('pathname',path_name);

    const [isMenuActive, setMenuActive] = useState(false);

    const toggleMenuClass = () => {
    setMenuActive(!isMenuActive);
    };

    return (
        <> 
        <button className="right-nav" onClick={toggleMenuClass}><i className="far fa-arrow-alt-circle-right"></i></button>
        <div className={isMenuActive ? 'left_sidebar openside': 'left_sidebar' } >
             <button className="right-nav" onClick={toggleMenuClass}><i className="fas fa-times"></i></button>
            <ul>
                { (module==='About' || module==='Cv') && (
                    <>
                <li className={ module==='About'  && ("active")}><Link to="/about">Biography</Link></li>
                <li className={ module==='Cv'  && ("active")}><Link to="/cv">CV</Link>
                    { module==='Cv'  && (
                    <ul className="subnav-sidebar">
                        {
                            sidebarData.cv_category &&
                            sidebarData.cv_category.length>0 &&
                            sidebarData.cv_category.map((item,key) => (
                         <li key={key} className={ path_name==='/cv/' + item.slug  && ("active")}>
                              <Link to={"/cv/" + item.slug}  onClick={toggleMenuClass}>{item.title}</Link>
                             {/* <a href={ process.env.REACT_APP_BASE_NAME + "/cv/" + item.slug}>{item.title}</a> */}
                             </li>
                        ))}
                    </ul>  
                    )}
                </li>
                </>
                )}
                { module==='Work' && (
                    <li className="active"><Link to={ "/works" }>Work</Link>
                        <ul className="subnav-sidebar">
                        {
                        sidebarData.work &&
                        sidebarData.work.length>0 &&
                        sidebarData.work.map((item,key) => (
                            <li key={key} ><Link to={ "/works/" + item.slug }>{item.title}</Link></li>
                        ))}
                        </ul>  
                    </li>
                )}
                { module==='Other-Show' && (
                    <li className="active"><Link to={ "/other-shows" }>Other Shows</Link>
                        <ul className="subnav-sidebar">
                        {
                        sidebarData.other_solo &&
                        sidebarData.other_solo.length>0 &&
                        sidebarData.other_solo.map((item,key) => (
                            <li key={key} ><Link to={ "/other-shows/" + item.slug }>{item.title}</Link></li>
                        ))}
                        </ul>  
                    </li>
                )}
                { module==='Solo-Show' && (
                    <li className="active"><Link to={ "/solo-shows" }>Solo Shows</Link>
                        <ul className="subnav-sidebar">
                        {
                        sidebarData.solo &&
                        sidebarData.solo.length>0 &&
                        sidebarData.solo.map((item,key) => (
                            <li key={key} ><Link to={ "/solo-show-details/" + item.slug }>{item.title}</Link></li>
                        ))}
                        </ul>  
                    </li>
                )}
                { module==='Publication' && (
                    <>
                    {
                    sidebarData.publictaion_category &&
                    sidebarData.publictaion_category.length>0 && (
                        <li className="active"><Link to={ "/publication" }>Publications</Link>
                            <ul className="subnav-sidebar">
                                {
                                    sidebarData.publictaion_category &&
                                    sidebarData.publictaion_category.length>0 &&
                                    sidebarData.publictaion_category.map((item,key) => (
                                <li key={key} className={ path_name==='/publication/' + item.slug  && ("active")}>
                                    <Link to={"/publication/" + item.slug}  onClick={toggleMenuClass}>{item.title}</Link>
                                    </li>
                                ))}
                            </ul>  
                        </li>
                    )}
                    </>
                )}
                { module==='Videos' && (
                        <li className="active"><Link to={ "/videos" }>Videos</Link>
                            <ul className="subnav-sidebar">
                    {
                    sidebarData.video &&
                    sidebarData.video.length>0 &&
                    sidebarData.video.map((item,key) => (
                        <li key={key} ><Link to={ "/videos/" + item.yt_video_id }>{item.title}</Link></li>
                    ))}
                    </ul>  
                    </li>
                )}
            </ul>

        </div>
 
        </>
    )
}
export default Sidebar;