import React from "react";
import { Modal } from "react-bootstrap";

// Function to convert a standard YouTube URL to an embed URL
const convertToEmbedUrl = (url) => {
  try {
    const urlObj = new URL(url);
    const videoId = urlObj.searchParams.get("v");
    return `https://www.youtube.com/embed/${videoId}`;
  } catch (error) {
    console.error("Invalid URL:", url, error);
    return "";
  }
};

const Video = (props) => {
  const { userdata } = props;

  // Check if userdata and yt_video_id are defined
  const ytVideoId = userdata?.yt_video_id || "";

  const embedUrl = ytVideoId.startsWith("https://") ? convertToEmbedUrl(ytVideoId) : `https://www.youtube.com/embed/${ytVideoId}`;

  return (
    <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton>{userdata?.title || "Video Title"}</Modal.Header>
      <Modal.Body>
        <iframe
          width="100%"
          height="500"
          src={embedUrl + "?autoplay=1&start=0"}
          title={userdata?.title || "Video"}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </Modal.Body>
    </Modal>
  );
};

export default Video;
