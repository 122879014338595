import React, { useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Container } from "react-bootstrap";
// import { Link } from 'react-router-dom';
import Sidebar from '../../Component/Sidebar';
import { loadOtherShowData } from "../../actions/otherShowActions";


const Othershows = (props) => {

    const slug = props.match.params.slug;

    const dispatch = useDispatch();
    useEffect(() => {
    dispatch(loadOtherShowData(slug));
    }, [dispatch, slug]);

    const {
        otherShowData,
    } = useSelector((state) => ({
      otherShowData: state.otherShowData,
    }));
    
    // console.log('otherShowData', otherShowData);

    return (

        <>

            <section className="page_title">
                <Container>
                    <h1>Other Shows</h1>
                </Container>
            </section>

            <section className="about_page">
                <Container>
                    <div className="main_contnat">
                        <div className="page_main_outer">
                            <Sidebar module={'Other-Show'}/>
                            <div className="pagemain_right">
                                <div className="soloshows other-shows-list">
                                    {
                                        otherShowData &&
                                        otherShowData.length>0 &&
                                        otherShowData.map( ( item, key ) => (
                                    <div className="horizantel_grid" key={key}>
                                        <figure><a href={ process.env.REACT_APP_PUBLIC_URL + "/other-show-details/" + item.slug}><img alt={item.title} src={item.media} /></a></figure>
                                        <div className="list_info">
                                            <h3><a href={ process.env.REACT_APP_PUBLIC_URL + "/other-show-details/" + item.slug}>{item.title}</a></h3>
                                            <div className="solor_venue">
                                                { !!(item.publish_date) ? (
                                                <span className="date"><img src={process.env.REACT_APP_PUBLIC_URL + "/assets/images/time.svg"} alt="Kanchana Gupta" />{item.publish_date}</span>
                                                ) : '' }
                                                { !!(item.address) ? (
                                                <span className="locationfix"><img src={process.env.REACT_APP_PUBLIC_URL + "/assets/images/location.svg"} alt="Kanchana Gupta" />{item.address}</span>
                                                ) : '' }
                                            </div>
                                            <p dangerouslySetInnerHTML={{__html: item.short_description}}></p>
                                            <a href={ process.env.REACT_APP_PUBLIC_URL + "/other-show-details/" + item.slug} className="readmore">Read more</a>
                                        </div>
                                    </div>
                                    )) }

                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </section>



        </>
    )
}
export default Othershows;