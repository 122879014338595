import * as types from '../actions/actionsTypes';
import initialState from './initialState';

export default function eventReducer(state = initialState.eventData, action){
    switch(action.type) {
        case types.LOAD_EVENT_DATA_SUCCESS:
            return action.eventData;
        default:
            return state;
    }
}