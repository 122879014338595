import React, { Fragment } from 'react';
import '@fortawesome/fontawesome-free/css/all.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../assets/css/App.css'; 
import '../assets/css/Media.css'; 
import Page from "../Pages";
// import './assets/css/media.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import { BrowserRouter, Route, Switch } from "react-router-dom";
// import Header from './components/Header' 
// import Footer from './components/Footer'  
// import Index from './pages/Index' 
// import About from './pages/About' 
// import Cv from './pages/Cv' 
// import Events from './pages/Events' 
  
const App = () => { 

  return (
    <Fragment>
        <Page />
    </Fragment>
    
  );
}

export default App;
