// eslint-disable-next-line import/no-anonymous-default-export
export default {
    homePageData: {},
    settingsData: {},
    sidebarData: {},
    aboutUsData: [],
    cvCategoryData: [],
    workData: [],
    soloShowData: [],
    otherShowData: [],
    mediaData: [],
    mediaGalleryData: [],
    publicationData: [],
    videoData: [],
    cVData: [],
    newsData: [],
    eventData: [],
    publicationDetailData: {},
  };
  