export const HOME_PAGE_DATA = "gethome";
export const SLIDER_DATA = "getsliders";
export const EVENT_DATA = "getevents";    
export const EVENT_BY_SLUG_DATA = "geteventsbyslug";   
export const ABOUT_US_DATA = "aboutus";
export const CV_CATEGORY_DATA = "getcvcategory";   
export const WORK_DATA = "getworklist";     
export const WORK_BY_SLUG_DATA = "getworkbyslug";    
export const WORK_DETAIL_DATA = "getworklist";    
export const SOLO_SHOW_DATA = "getsoloshow";
export const SOLO_SHOW_BY_SLUG_DATA = "getsoloshowbyslug";
export const OTHER_SHOW_DATA = "getothersoloshow";
export const OTHER_SHOW_BY_SLUG_DATA = "getothershowbyslug";
export const PUBLICATION_DATA = "getpublication";
export const PUBLICATION_BY_SLUG_DATA = "getpublicationbyslug";
export const PUBLICATION_DETAIL_BY_SLUG_DATA = "getpublicationdetailbyslug";
export const MEDIA_DATA = "getmedia";
export const MEDIA_GALLERY_DATA = "getmediagallery";
export const VIDEO_DATA = "getvideo";
export const VIDEO_BY_YTD_ID_DATA = "getvideobyid";
export const CV_DATA = "getcv";
export const CV_BY_SLUG_DATA = "getcvbyslug";
export const NEWS_DATA = "getnews";    
export const NEWS_BY_SLUG_DATA = "getnewsbyslug";  
export const SUBMIT_NEWSLETTER = "newsletter_subscribe";
export const SETTING_DATA = "getsettings";
export const SIDEBAR_DATA = "getsidebar";
export const SUBMIT_RSVP = "submit_rsvp";