import * as types from '../actions/actionsTypes';
import initialState from './initialState';

export default function settingsReducer(state = initialState.settingsData, action){
    switch(action.type) {
        case types.LOAD_SETTING_DATA_SUCCESS:
            return action.settingsData;
        default:
            return state;
    }
}