import React, { useEffect, Fragment } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Container } from "react-bootstrap"; 
import { loadNewsData } from "../../actions/newsActions";

const Newsdetail = (props) => {
    
    const slug = props.match.params.slug;
    
    // console.log('slug',slug);

    const dispatch = useDispatch();
    useEffect(() => {
    dispatch(loadNewsData(slug));
    }, [dispatch, slug]);
    
    const {
      newsData,
    } = useSelector((state) => ({
      newsData: state.newsData,
    }));
    
    // console.log('newsData',newsData);

    return (

        <>
        { newsData && 
          newsData.length>0 &&
          newsData.map( ( item, key) => (
          (
          <Fragment key={key}>

            <section className="page_title">
                <Container>
                    <h1>{item.title}</h1>
                </Container>
            </section>

            <section className="about_page event_detail_page">
                <Container>
                    <div className="event_detail_page">
                    { item.media && ( <figure><img src={item.media} alt={item.title} /></figure> )}

                       <div className="event_detail_info_part">
                       <div className="news_left event_left"> 
                        <div className="event_detail_header">                       
                            <div className="event_detail_caption">
                                { item.publish_date && (
                                <span className="date"><img src={process.env.REACT_APP_PUBLIC_URL + "/assets/images/time.svg"} alt="Kanchana Gupta" />{item.publish_date}</span>
                                )}
                                { !!item.address && (
                                <span className="locationfix"><img src={ process.env.REACT_APP_PUBLIC_URL + "/assets/images/location.svg"} alt="Kanchana Gupta" />{item.address}</span>
                                )}
                            </div>
                            { !!item.address && (
                                <a href={ "https://www.google.com/maps/search/" + item.link_address } target="_blank" rel="noreferrer" className="readmore">Location on Map</a>
                            )}
                         </div>

                         <p dangerouslySetInnerHTML={{__html: item.description}}></p>

                        </div> 

                         </div>
                    </div>
                </Container>                    
            </section>

            </Fragment>
    ) )) }


        </>
    )
}
export default Newsdetail;